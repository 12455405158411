<template>
	<div>
		<div @click="refreshDevice" id="update-btn">Update</div>

		<div id="list-device">
			<loading v-model:active="isLoading" :is-full-page="false" />

			<div class="status">
				<div class="top-air air">
					<div
						v-for="device in devices"
						:key="device.id"
						class="air-condition"
					>
						<div>
							<span>{{
								device.temperature
									? device.temperature + "℃"
									: ""
							}}</span>
							<img
								v-if="device?.mode == 'cold'"
								class="icon_air"
								src="../assets/images/icon_snowflake.svg"
							/>
							<img
								v-if="device?.sensor == true"
								class="icon_air"
								src="../assets/images/icon_bluetooth.svg"
							/>
						</div>
						<div>
							<img
								v-if="device?.type != 'remote_erv'"
								src="../assets/images/icon_aircon.svg"
							/>
							<img
								height="71"
								v-if="device?.type == 'remote_erv'"
								src="../assets/images/icon_ERV_1F.svg"
							/>
						</div>
						<p>{{ device.name }}</p>
					</div>
				</div>
			</div>
			<div class="location">
				<div
					v-show="room.sensorName"
					v-for="room in rooms"
					:key="room.id"
				>
					<img src="../assets/images/icon_bluetooth.svg" />
					<div>
						<span>{{ room.sensorName }}</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Loading from "vue-loading-overlay";
import appService from "../services/app";

export default {
	components: { Loading },
	props: {
		room: Object,
		rooms: Array,
	},
	mounted() {
		// this.getListDevice();
	},
	data: () => ({
		isLoading: false,
		devices: [],
	}),
	watch: {
		rooms: function (newVal, oldVal) {
			this.getListDevice();
		},
	},
	methods: {
		chooseDevice(device) {
			this.$emit("change-Layout", device.id);
		},
		async getListDevice() {
			let allDevices = [];
			this.isLoading = true;

			for (var i = 0; i < this.rooms.length; i++) {
				let ele = this.rooms[i];
				let response = await appService.getDevices(ele.id);
				let devices = response.data.data;
				devices.map((device) => {
					allDevices.push(device);
				});
			}
			this.devices = allDevices;
			console.log(this.devices);
			this.isLoading = false;
		},
		refreshDevice() {
			this.$emit("resetRefreshTimer");
			this.getListDevice();
		},
	},
};
</script>
