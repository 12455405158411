import config from "./config";
import axios from "axios";

async function getProperties() {
	try {
		let response = await axios.get("/properties");
		return response;
	} catch (err) {
		return err.response.status;
	}
}

async function getRooms(id) {
	try {
		let url = "/rooms/" + id;
		let response = await axios.get(url);
		return response;
	} catch (err) {
		return err.response;
	}
}

async function getDevices(id) {
	let url = "/devices/" + id;
	let response = await axios.get(url);
	return response;
}

async function getDevice(id) {
	let url = "/device/" + id;
	let response = await axios.get(url);
	return response;
}

async function getAlerts(id) {
	let url = "/alerts/" + id;
	let response = await axios.get(url);
	return response;
}

async function updateDevice(id, body) {
	let url = "/device/" + id;
	let response = await axios.post(url, body);
	return response;
}

export default {
	getProperties: getProperties,
	getRooms: getRooms,
	getAlerts: getAlerts,
	getDevices: getDevices,
	updateDevice: updateDevice,
	getDevice: getDevice,
};
