<template>
	<div id="device-name-control">
		<span>{{ device?.name }}</span>
		<span>
			<img
				v-if="device?.type?.toLowerCase() == typeIDU"
				src="../assets/images/idu.png"
			/>
			<img
				v-if="device?.type?.toLowerCase() == typeRemoteERV"
				src="../assets/images/remote_erv.png"
			/>
			<img
				v-if="device?.type?.toLowerCase() == typeMechanical"
				src="../assets/images/idu_ven.png"
			/>
		</span>
	</div>
	<div id="airControllerContainer">
		<img
			v-if="
				device?.type?.toLowerCase() == typeIDU ||
				device?.type?.toLowerCase() == typeMechanical
			"
			:style="{ top: topIcon, left: leftIcon }"
			id="device_status_button"
			src="../assets/images/device_status_button_select_temp.svg"
		/>
		<div id="airController">
			<label class="switch">
				<input type="checkbox" v-model="isOff" />
				<span class="slider round"></span>
			</label>
			<div
				v-if="
					device?.type?.toLowerCase() == typeIDU ||
					device?.type?.toLowerCase() == typeMechanical
				"
				id="btn-control"
			>
				<a @click="minusTempo()" class="minus-btn">
					<img
						src="../assets/images/device_status_button_minus.svg"
					/>
				</a>
				<div class="detail-tempo">
					{{ currentTemp }}
					<span style="font-size: 35px !important">℃</span>
				</div>
				<a @click="addTempo()" class="add-btn">
					<img src="../assets/images/device_status_button_add.svg" />
				</a>
			</div>

			<div>
				<div
					v-if="device?.type?.toLowerCase() == typeRemoteERV"
					id="remote-control"
				>
					<div id="remote-control-1">
						<div>
							<img
								v-if="deviceMode?.toLowerCase() == 'auto'"
								src="../assets/images/icon_remote_mode_auto_active.svg"
							/>
							<img
								v-if="deviceMode?.toLowerCase() != 'auto'"
								src="../assets/images/icon_remote_mode_auto.svg"
							/>
						</div>
						<div>
							<img
								v-if="deviceMode?.toLowerCase() == 'nv'"
								src="../assets/images/icon_remote_mode_NV_active.svg"
							/>
							<img
								v-if="deviceMode?.toLowerCase() != 'nv'"
								src="../assets/images/icon_remote_mode_NV.svg"
							/>
						</div>
						<div>
							<img
								v-if="deviceMode?.toLowerCase() == 'he'"
								src="../assets/images/icon_remote_mode_HE_active.svg"
							/>
							<img
								v-if="deviceMode?.toLowerCase() != 'he'"
								src="../assets/images/icon_remote_mode_HE.svg"
							/>
						</div>
					</div>
					<div id="remote-control-2">
						<div>
							<img
								v-if="
									device?.options?.airflow?.toLowerCase() ==
									'high'
								"
								src="../assets/images/icon_fan_mode_high_active.svg"
							/>
							<img
								v-if="
									device?.options?.airflow?.toLowerCase() !=
									'high'
								"
								src="../assets/images/icon_fan_mode_high.svg"
							/>
						</div>
						<div>
							<img
								v-if="
									device?.options?.airflow?.toLowerCase() ==
									'low'
								"
								src="../assets/images/icon_fan_mode_low_active.svg"
							/>
							<img
								v-if="
									device?.options?.airflow?.toLowerCase() !=
									'low'
								"
								src="../assets/images/icon_fan_mode_low.svg"
							/>
						</div>
					</div>
				</div>
			</div>

			<div
				v-if="
					device?.type?.toLowerCase() == typeIDU ||
					device?.type?.toLowerCase() == typeMechanical
				"
				id="button-control"
			>
				<div
					v-if="
						device?.type?.toLowerCase() == typeIDU ||
						device?.type?.toLowerCase() == typeMechanical
					"
					id="idu-control"
				>
					<div>
						<img
							v-if="deviceMode?.toLowerCase() == 'auto'"
							src="../assets/images/icon_mode_auto_active.svg"
						/>
						<img
							v-if="deviceMode?.toLowerCase() != 'auto'"
							src="../assets/images/icon_mode_auto.svg"
						/>
					</div>
					<div>
						<img
							v-if="deviceMode?.toLowerCase() == 'dry'"
							src="../assets/images/icon_mode_dry_active.svg"
						/>
						<img
							v-if="deviceMode?.toLowerCase() != 'dry'"
							src="../assets/images/icon_mode_dry.svg"
						/>
					</div>
					<div>
						<img
							v-if="deviceMode?.toLowerCase() == 'heat'"
							src="../assets/images/icon_mode_heat_active.svg"
						/>
						<img
							v-if="deviceMode?.toLowerCase() != 'heat'"
							src="../assets/images/icon_mode_heat.svg"
						/>
					</div>
					<div>
						<img
							v-if="deviceMode?.toLowerCase() == 'cool'"
							src="../assets/images/icon_mode_cool_active.svg"
						/>
						<img
							v-if="deviceMode?.toLowerCase() != 'cool'"
							src="../assets/images/icon_mode_cool.svg"
						/>
					</div>
					<div>
						<img
							v-if="deviceMode?.toLowerCase() == 'blow'"
							src="../assets/images/icon_mode_blow_active.svg"
						/>
						<img
							v-if="deviceMode?.toLowerCase() != 'blow'"
							src="../assets/images/icon_mode_blow.svg"
						/>
					</div>
				</div>
				<div
					v-if="device?.type?.toLowerCase() == typeMechanical"
					id="idu-control-ven"
				>
					<div>
						<img
							v-if="
								device?.options?.airflow?.toLowerCase() ==
								'high'
							"
							src="../assets/images/icon_fan_mode_high_active.svg"
						/>
						<img
							v-if="
								device?.options?.airflow?.toLowerCase() !=
								'high'
							"
							src="../assets/images/icon_fan_mode_high.svg"
						/>
					</div>
					<div>
						<img
							v-if="
								device?.options?.airflow?.toLowerCase() == 'low'
							"
							src="../assets/images/icon_fan_mode_low_active.svg"
						/>
						<img
							v-if="
								device?.options?.airflow?.toLowerCase() != 'low'
							"
							src="../assets/images/icon_fan_mode_low.svg"
						/>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import appService from "../services/app";

const temperatureArray = [
	16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
];

export default {
	props: {
		device: Object,
	},
	data: () => ({
		data: [],
		topIcon: "273px",
		leftIcon: "17px",
		currentDot: -7,
		currentTemp: 16,
		isOff: false,
		typeIDU: "idu",
		typeRemoteERV: "remote_erv",
		typeMechanical: "mechanical_erv",
		deviceMode: "",
	}),
	mounted() {
		this.loadData();
		// this.setupCircle();
	},
	watch: {
		device: function (newVal, oldVal) {
			this.isOff = !this.device?.on;
			this.currentTemp = this.device?.options?.temperature;
			this.deviceMode = this.device?.options?.mode;

			let index = temperatureArray.findIndex(
				(ele) => ele * 1 == this.currentTemp * 1
			);
			if (index > 0) {
				this.currentDot = index - 7;
				this.loadData();
			}
		},
	},
	methods: {
		updateDevice() {
			let body = {
				id: this.device.id,
				type: this.device.type,
				options: {
					temperature: 27.0,
				},
			};
			console.log(body);
			appService.updateDevice(this.device.id, body).then((res) => {
				console.log(res);
			});
		},
		minusTempo() {
			if (this.currentDot > -7) {
				this.currentDot--;
				this.currentTemp--;
				this.loadData();
			}

			this.updateDevice();
		},
		addTempo() {
			if (this.currentDot < 7) {
				this.currentDot++;
				this.currentTemp++;
				this.loadData();
			}
		},
		loadData() {
			let frags = 360 / 31;
			let r = 210;
			let mainHeight = 440;
			let thatai = (frags / 180) * this.currentDot * Math.PI;
			let posx = Math.round(r * Math.cos(thatai));
			let posy = Math.round(r * Math.sin(thatai));
			this.topIcon = mainHeight / 2 - posx + "px";
			this.leftIcon = mainHeight / 2 + posy + "px";
		},
	},
};
</script>
<style></style>
