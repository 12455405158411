<template>
	<div class="main">
		<div @click="logout" id="logout-btn">
			<img width="20" src="../../../assets/images/logout.png" />
			<span>Log out</span>
		</div>
		<loading v-model:active="isLoading" :is-full-page="true" />
		<div class="row">
			<div
				v-if="this.currentLayout == 'layout1'"
				id="section-property"
				class="col-md-2"
			>
				<b-accordion>
					<b-accordion-item
						visible
						v-for="property in properties"
						:key="property.id"
						:title="property.name"
					>
						<a
							class="left-nav nav-link"
							:class="{ active: item.isActive }"
							@click="openProperty(item, property)"
							v-for="item in property.maps"
							:key="item.id"
							>{{ item.name }}</a
						>
					</b-accordion-item>
				</b-accordion>
				<div @click="changeLayout()" id="dashboard-btn">
					<img src="../../../assets/images/dashboard_btn.png" />
				</div>
			</div>
			<ListMap
				:property="currentMap"
				:currentRoom="currentRoom"
				:rooms="rooms"
				@displaySmallRoom="displaySmallRoom"
				@changeDataType="changeDataType"
				@back-click="changeLayout()"
				v-if="this.currentLayout == 'layout2'"
			/>

			<div id="section-dashboard" class="col-md-6">
				<ul class="nav nav-tabs" id="myTab" role="tablist">
					<li
						v-for="layout in savedLayouts"
						:key="layout.id"
						:title="layout.name"
						class="nav-item"
						role="presentation"
					>
						<button
							@click="openProperty(layout, currentProperty)"
							:class="{ active: layout.isActive }"
							class="nav-link"
							id="profile-tab"
							data-bs-toggle="tab"
							:data-bs-target="'#' + 'id' + layout.id"
							type="button"
							role="tab"
							aria-controls="profile"
							aria-selected="false"
						>
							{{ layout.name }}
						</button>
					</li>
				</ul>
				<div class="tab-content" id="myTabContent">
					<div
						v-for="layout in savedLayouts"
						:key="layout.id"
						:class="{ 'show active': layout.isActive }"
						class="tab-pane fade"
						:id="'id' + layout.id"
						role="tabpanel"
						aria-labelledby="home-tab"
					>
						<div class="title-panel">
							<div>{{ layout.fullName }}</div>
							<div style="font-size: 16px">
								{{ currentMapType }}
							</div>
						</div>
						<div id="main-map">
							<div :style="getMainMap(layout)" id="map-detail">
								<div
									v-for="room in rooms"
									:key="room.name"
									:class="[
										{
											dot_active:
												room == currentRoom &&
												currentLayout == 'layout2',
										},
										getClassName(room),
									]"
									:style="{
										'background-color': getRoomColor(room),
										top: room.positionX + 'px',
										left: room.positionY + 'px',
									}"
									@click="pickRoom(room)"
									class="dot-device"
								></div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-md-4">
				<div id="section-detail">
					<ListDevice
						@change-Layout="changeLayout"
						@resetRefreshTimer="resetRefreshTimer"
						@refreshDevice="refreshDevice"
						:room="currentRoom"
						:rooms="rooms"
						:devices="devices"
						v-show="this.currentLayout == 'layout1'"
					/>
					<AirDetail
						@prev-room="changeRoom('prev')"
						@next-room="changeRoom('next')"
						:pickedDevice="pickedDevice"
						:room="currentRoom"
						:type="currentMapType"
						v-show="this.currentLayout == 'layout2'"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import axios from "axios";
import AirDetail from "../../../components/AirDetail.vue";
import ListDevice from "../../../components/ListDevice.vue";
import ListMap from "../../../components/ListMap.vue";
import appService from "../../../services/app";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { mapState, mapActions } from "vuex";

export default {
	name: "Home",
	components: {
		AirDetail,
		ListDevice,
		ListMap,
		Loading,
	},
	data: () => ({
		products: [],
		isLoading: false,
		properties: [],
		savedLayouts: [],
		currentLayout: "layout1",
		currentRoom: {},
		devices: [],
		currentMap: {},
		currentProperty: {},
		rooms: [],
		alert: "",
		pickedDevice: {},
		currentMapType: "IAQ Score",
		roomData: {},
	}),
	computed: {},
	mounted() {
		this.getProperties();
	},
	methods: {
		...mapActions("authfack", ["logout"]),
		async pickRoom(room) {
			if (this.currentRoom != room) {
				this.currentRoom = room;
				this.getRoomData();
			}
		},
		changeDataType(type) {
			this.currentMapType = type;
			this.getRoomData();
		},
		getRoomData() {
			let type = this.currentMapType;
			if (type == "Temperature & Humidity") {
				this.roomData = this.currentRoom.temperatureHumidity;
			} else if (type == "CO2 Concentration") {
				this.roomData = this.currentRoom.co2Concentration;
			} else if (type == "Nanoe Concentration") {
				this.roomData = this.currentRoom.nanoeConcentration;
			} else {
				this.roomData = this.currentRoom.overall;
			}
		},
		async changeRoom(type) {
			console.log(type);
			let index = this.rooms.findIndex(
				(room) => room == this.currentRoom
			);
			if (type == "prev") {
				if (index > 0) {
					this.currentRoom = this.rooms[index - 1];
				}
			} else if (type == "next") {
				if (index < this.rooms.length - 1) {
					this.currentRoom = this.rooms[index + 1];
				}
			}
			this.getRoomData();

			let response = await appService.getDevices(this.currentRoom?.id);
			this.devices = response.data.data;
		},
		getClassName(room) {
			let className = "status-" + this.roomData?.text;
			return className;
		},
		displaySmallRoom(room, type) {
			if (room != this.currentRoom) {
				this.currentRoom = room;
			}
			if (type != this.currentSmallRoomType) {
				this.currentSmallRoomType = type;
			}
		},
		changeLayout(device_id) {
			// console.log(device_id);
			if (device_id) {
				this.pickedDevice = this.currentRoom.devices.find(
					(ele) => ele.id == device_id
				);
				// console.log("picked device", this.pickedDevice);
			}
			if (this.currentLayout == "layout1") {
				this.currentLayout = "layout2";
			} else {
				this.currentLayout = "layout1";
				this.currentMapType = "IAQ Score";
			}
			this.resetRefreshTimer();
		},
		async loadsavedLayouts() {
			let layouts = await localStorage.getItem("panaLayout");
			if (layouts) {
				this.savedLayouts = JSON.parse(layouts);
			}
		},
		async openProperty(item, property) {
			if (property != this.currentProperty) {
				await localStorage.removeItem("panaLayout");
				this.savedLayouts = [];
				this.currentProperty = property;
			}

			this.currentMap = item;
			this.isLoading = true;
			let response = await appService.getRooms(item.id);
			await localStorage.setItem(
				"rooms",
				JSON.stringify(response.data.data)
			);
			this.rooms = response.data.data;
			// let _rooms = await localStorage.getItem("rooms");
			// if (_rooms) {
			// 	this.rooms = JSON.parse(_rooms);
			// 	console.log(this.rooms[0]);
			// }

			this.pickRoom(this.rooms[0]);
			this.isLoading = false;

			this.resetRefreshTimer();

			let checkAdded = false;
			for (var i = 0; i < this.savedLayouts.length; i++) {
				let layout = this.savedLayouts[i];
				if (layout.id == item.id) {
					layout.isActive = true;
					checkAdded = true;
				} else {
					layout.isActive = false;
				}
			}
			if (checkAdded == false) {
				item.isActive = true;
				this.savedLayouts.push(item);
			}
		},
		resetRefreshTimer() {
			if (this.interval) {
				clearInterval(this.interval);
			}
			this.interval = setInterval(() => this.refreshProperty(), 300000);
		},
		async getProperties() {
			let response = await appService.getProperties();
			console.log("properties", response);
			if (response == 403) {
				this.logout();
			} else {
				this.properties = response.data.data;

				await this.loadsavedLayouts();
				if (this.savedLayouts.length == 0) {
					this.openProperty(
						this.properties[1].maps[0],
						this.properties[1]
					);
				}
			}
		},
		getMainMap(layout) {
			return "background: url(" + layout.image + ") no-repeat";
		},
		refreshProperty() {
			this.openProperty(this.currentMap, this.currentProperty);
		},
		async refreshDevice() {
			// this.isLoading = true;
			let response = await appService.getDevices(this.currentRoom?.id);
			this.devices = response.data.data;
			console.log(this.devices);
			// this.isLoading = false;
		},
		getRoomColor(room) {
			let type = this.currentMapType;
			if (type == "Temperature & Humidity") {
				return room.temperatureHumidity.color;
			} else if (type == "CO2 Concentration") {
				return room.co2Concentration.color;
			} else if (type == "Nanoe Concentration") {
				return room.nanoeConcentration.color;
			} else {
				return room.overall.color;
			}
		},
	},
	async created() {
		this.isLoading = true;
	},
};
</script>
