<template>
	<div
		ref="catpic"
		v-if="showChart"
		id="chart-wrapper"
		:style="{ height: chartHeight, 'background-color': chartBg }"
	>
		<p v-if="noGraph">No graph data</p>
		<canvas v-show="!noGraph" id="lineChart" width="100%"></canvas>
	</div>
	<div @click="requestFullScreen" id="zoom-in-btn">
		<img src="../assets/images/icon_zoom_in.svg" />
	</div>
</template>

<script>
import { Chart, registerables } from "chart.js";
// import ChartDataLabels from "chartjs-plugin-datalabels";
// import { directive as fullscreen } from "vue-fullscreen";
// import fullscreen from "vue-fullscreen";
Chart.register(...registerables);

export default {
	components: {},
	props: {
		graphs: Array,
		pickedChartType: String,
	},
	data: () => ({
		myLineChart: Object,
		picked: "temphud",
		fullscreen: false,
		teleport: false,
		options: {
			target: ".fullscreen-wrapper",
			callback(isFullscreen) {
				console.log("isFullscreen", isFullscreen);
			},
		},
		chartHeight: "294px",
		chartBg: "transparent",
		noGraph: false,
		showChart: true,
	}),
	directives: {
		// fullscreen,
	},
	mounted() {
		this.renderLineChart();
	},
	watch: {
		graphs: function (newVal, oldVal) {
			this.myLineChart.destroy();
			this.renderLineChart();
		},
	},
	methods: {
		requestFullScreen() {
			this.chartBg = "#FFFFFF";
			const rfs =
				this.$refs.catpic.requestFullscreen ||
				this.$refs.catpic.webkitRequestFullScreen ||
				this.$refs.catpic.mozRequestFullScreen ||
				this.$refs.catpic.msRequestFullscreen;
			rfs.call(this.$refs.catpic);

			if (document.addEventListener) {
				document.addEventListener(
					"fullscreenchange",
					exitHandler,
					false
				);
				document.addEventListener(
					"mozfullscreenchange",
					exitHandler,
					false
				);
				document.addEventListener(
					"MSFullscreenChange",
					exitHandler,
					false
				);
				document.addEventListener(
					"webkitfullscreenchange",
					exitHandler,
					false
				);
			}

			function exitHandler() {
				if (
					!document.webkitIsFullScreen &&
					!document.mozFullScreen &&
					!document.msFullscreenElement
				) {
					let chartwrapper = document.getElementById("chart-wrapper");
					chartwrapper.style.backgroundColor = "transparent";
				}
			}
		},

		renderLineChart() {
			var ctx = document.getElementById("lineChart");
			ctx.getContext("2d");
			let datasets = [];
			if (this.graphs) {
				if (this.pickedChartType == "temphud") {
					datasets = [
						{
							label: "Temp ℃",
							borderColor: "#25CAC6",
							borderWidth: 5,
							data: this.graphs[0]?.values,
							yAxisID: "y1",
						},
						{
							label: "Humidity",
							borderColor: "#008CB3",
							borderWidth: 5,
							data: this.graphs[1]?.values,
							yAxisID: "y2",
						},
					];
				} else if (this.pickedChartType == "co2") {
					datasets = [
						{
							label: "CO2",
							borderColor: "#C46608",
							borderWidth: 5,
							data: this.graphs[2]?.values,
							yAxisID: "y1",
						},
					];
				} else if (this.pickedChartType == "nanoe") {
					datasets = [
						{
							label: "Nanoe",
							borderColor: "#959595",
							borderWidth: 5,
							data: this.graphs[3]?.values,
							yAxisID: "y1",
						},
					];
				}
			}
			var data = {
				// labels: ["00.00", "04.00", "08.00", "12.00", "16.00", "20.00"],
				datasets: datasets,
			};
			var options = {
				responsive: true,
				maintainAspectRatio: false,
				plugins: {
					legend: {
						display: true,
					},
					tooltip: {},
				},
				parsing: {
					xAxisKey: "key",
					yAxisKey: "value",
				},
				scales: {
					x: { ticks: { autoSkip: true } },
					y1: {
						ticks: {
							color: "#25CAC6",
							font: {
								size: 18,
								weight: 500,
							},
						},
					},
					y2: {
						display: this.picked == "temphud" ? true : false,
						ticks: {
							color: "#008CB3",
							font: {
								size: 18,
								weight: 500,
							},
						},
						position: "right",
					},
				},
			};
			if (datasets[0]?.data?.length == 0) {
				this.noGraph = true;
			} else {
				this.noGraph = false;
			}
			this.myLineChart = new Chart("lineChart", {
				type: "line",
				data: data,
				options: options,
			});
		},
	},
};
</script>
<style>
#pick-trend-map {
	margin-left: 30px;
	margin-top: 13.5px;
	margin-bottom: 20px;
	height: 28px;
}
#pick-trend-map label {
	margin-right: 30px;
	margin-left: 8px;
}

#pick-trend-map input {
	height: 15px;
	width: 15px;
	margin-top: 5px;
}

#pick-trend-map input:checked {
	background-color: #ffa500;
}

#chart-wrapper {
	margin-top: 10px;
	padding-left: 19px;
	padding-right: 21px;
}

.fullscreen-wrapper {
	background-color: #ffffff;
}

#zoom-in-btn {
	cursor: pointer;
	position: absolute;
	right: 40px;
	top: 3px;
}
</style>
