<template>
	<div class="col-md-2">
		<div @click="onBack()" id="back-btn">
			<img src="../assets/images/back_btn.svg" />
		</div>
		<div id="list-map">
			<div
				@click="changeDataType(layout.name)"
				v-for="layout in layouts"
				:key="layout.id"
				style="cursor: pointer"
			>
				<div class="layout-mini-title">
					{{ layout.name }}
				</div>
				<div :style="getBackground()" class="layout-mini-image">
					<div
						v-for="room in rooms"
						:key="room.name"
						:class="[getClassName(room)]"
						:style="{
							'background-color': getBgRoom(layout, room),
							top: (room.positionX * 240) / 800 + 'px',
							left: 40 + (room.positionY * 240) / 800 + 'px',
						}"
						class="dot-device"
					></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	components: {},
	props: {
		property: Object,
		rooms: Array,
		currentRoom: Object,
	},
	data: () => ({
		layouts: [
			{
				id: 1,
				name: "Temperature & Humidity",
			},
			{
				id: 2,
				name: "CO2 Concentration",
			},
			{
				id: 3,
				name: "Nanoe Concentration",
			},
		],
		currentSmallRoom: {},
		currentSmallRoomType: "IAQ Score",
	}),
	mounted() {
		this.getListMap();
	},
	methods: {
		changeDataType(type) {
			this.layouts.find((ele) => ele.name == type).name =
				this.currentSmallRoomType;
			this.currentSmallRoomType = type;

			this.$emit("changeDataType", type);
		},
		pickSmallRoom(room, type) {
			this.currentSmallRoom = room;
			this.currentSmallRoomType = type;
			this.$emit("displaySmallRoom", room, type);
		},
		onBack() {
			this.$emit("back-click");
		},
		getListMap() {
			console.log("getListMap");
		},
		getBackground() {
			return "background: url(" + this.property.image + ") no-repeat";
		},
		getClassName(room) {
			let className = "status-" + room.overall?.text;
			return className;
		},
		getBgRoom(layout, room) {
			if (layout.name == "Temperature & Humidity") {
				return room.temperatureHumidity?.color;
			} else if (layout.name == "CO2 Concentration") {
				return room.co2Concentration?.color;
			} else if (layout.name == "Nanoe Concentration") {
				return room.nanoeConcentration?.color;
			} else {
				return room.overall.color;
			}
		},
	},
};
</script>
<style>
.mapActive {
	border: 4px solid #142ebf;
}
</style>
