<template>
	<div>
		<img
			v-if="errorCount == 0"
			style="width: 100%; height: 69px"
			src="../assets/images/no_error.png"
		/>
		<div v-if="errorCount > 0" class="top-alert">
			<img src="../assets/images/icon_warning_sign.png" />
			<span class="alert-msg"> {{ errorCount }} error(s) available </span>
		</div>
		<div
			:style="{ 'background-color': roomData?.color }"
			class="common-status-panel"
		>
			<div @click="prevRoom" id="btn-prev-room">
				<img src="../assets/images/prev_room.svg" />
			</div>
			<div @click="nextRoom" id="btn-next-room">
				<img src="../assets/images/next_room.svg" />
			</div>
			<div class="status-date">{{ room?.datetime }}</div>
			<p class="status">{{ roomData?.text }}</p>
			<p class="status-score">{{ roomData?.score }}</p>
		</div>
		<div>
			<div class="air-index">
				<div class="tempo">
					<div>
						<img src="../assets/images/icon_temperature.svg" />
					</div>
					<span>{{ room?.temperature }}℃</span>
				</div>
				<div class="humidity">
					<div><img src="../assets/images/icon_humidity.svg" /></div>
					<span>{{ room?.humidity }}%</span>
				</div>
				<div class="air-co2">
					<div><img src="../assets/images/icon_CO2.svg" /></div>
					<span>{{ room?.co2 }}ppm</span>
				</div>
				<div class="air-nanoe">
					<div>
						<img
							src="../assets/images/icon_nanoe_technology_active.svg"
						/>
					</div>
					<span>{{ room?.nanoe }}ppm</span>
				</div>
			</div>
			<div class="graph">
				<ul class="nav nav-tabs" id="myTab" role="tablist">
					<li class="nav-item" role="presentation">
						<button
							class="nav-link active"
							id="trend-graph"
							data-bs-toggle="tab"
							data-bs-target="#graph-trend"
							type="button"
							role="tab"
							aria-controls="home"
							aria-selected="true"
						>
							Trend Graph
						</button>
					</li>
					<li class="nav-item" role="presentation">
						<button
							class="nav-link"
							id="device-status"
							data-bs-toggle="tab"
							data-bs-target="#graph-device"
							type="button"
							role="tab"
							aria-controls="graph-device"
							aria-selected="false"
						>
							Device Status
						</button>
					</li>
				</ul>
				<div class="tab-content" id="myTabContent">
					<div
						class="tab-pane fade show active"
						id="graph-trend"
						role="tabpanel"
						aria-labelledby="home-tab"
					>
						<div id="pick-trend-map">
							<input
								type="radio"
								id="temphud"
								value="temphud"
								v-model="pickedChartType"
							/>
							<label for="one">
								<img src="../assets/images/temp_hud.png" />
							</label>

							<input
								type="radio"
								id="co2"
								value="co2"
								v-model="pickedChartType"
							/>
							<label for="one">
								<img src="../assets/images/c02.png" />
							</label>
							<input
								type="radio"
								id="nanoe"
								value="nanoe"
								v-model="pickedChartType"
							/>
							<label for="one">
								<img src="../assets/images/nanoe.png" />
							</label>
						</div>
						<LineChart
							:pickedChartType="pickedChartType"
							v-if="room && renderChart"
							:graphs="room?.graphs"
						/>
					</div>

					<div
						class="tab-pane fade"
						id="graph-device"
						role="tabpanel"
					>
						<div id="device-detail">
							<div
								v-if="devices?.length > 1"
								:style="{
									cursor:
										currentDeviceIndex > 0
											? 'pointer'
											: 'not-allowed',
								}"
								@click="prevDevice()"
								id="btn-prev-device"
							>
								<img
									src="../assets/images/device_status_button_left.svg"
								/>
							</div>
							<div
								v-if="devices?.length > 1"
								:style="{
									cursor:
										currentDeviceIndex < devices?.length - 1
											? 'pointer'
											: 'not-allowed',
								}"
								@click="nextDevice()"
								id="btn-next-device"
							>
								<img
									src="../assets/images/device_status_button_right.svg"
								/>
							</div>
							<AirControl :device="currentDevice" />
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import chartHelper from "../helper/chartHelper";
import { defineComponent } from "vue";
import { Chart, registerables } from "chart.js";
Chart.register(...registerables);
import slider from "vue3-slider";
import "vue3-circle-progress/dist/circle-progress.css";
import CircleProgress from "vue3-circle-progress";
import AirControl from "./AirControl";
import LineChart from "../components/LineChart.vue";
import appService from "../services/app";

export default {
	components: {
		LineChart,
		AirControl,
	},
	props: {
		room: Object,
		pickedDevice: Object,
		type: String,
	},
	data: () => ({
		tempo: 0,
		chartData: [],
		chartEle: ["temperature", "humidity"],
		progressPercent: 70,
		gradient: {
			angle: 90,
			startColor: "#09B3DE",
			stopColor: "#C11F1F",
		},
		devices: [],
		currentDevice: {},
		currentDeviceIndex: 0,
		errorCount: 0,
		renderChart: true,
		roomData: {},
		pickedChartType: "temphud",
	}),
	watch: {
		pickedDevice: function (newVal, oldVal) {
			if (this.pickedDevice.id.length > 0) {
				this.currentDevice = this.pickedDevice;
				this.currentDeviceIndex = this.room?.devices?.findIndex(
					(ele) => ele.id == this.pickedDevice.id
				);
			}
		},
		pickedChartType: function (newVal, oldVal) {
			this.toogleChart();
		},
		room: function (newVal, oldVal) {
			this.toogleChart();
			this.devices = this.room?.devices;
			if (this.devices?.length > 0) {
				this.currentDevice = this.devices[0];
				this.currentDeviceIndex = 0;
			}
			if (this.room?.id > 0) {
				appService.getAlerts(this.room?.id).then((res) => {
					if (!res.data?.data) {
						this.errorCount = 0;
					} else {
						this.errorCount = res.data?.data?.length;
					}
				});
			}
			this.getRoomData();
		},
		type: function (newVal, oldVal) {
			this.getRoomData();
		},
	},
	methods: {
		getRoomData() {
			if (this.type == "Temperature & Humidity") {
				this.roomData = this.room?.temperatureHumidity;
			} else if (this.type == "CO2 Concentration") {
				this.roomData = this.room?.co2Concentration;
			} else if (this.type == "Nanoe Concentration") {
				this.roomData = this.room?.nanoeConcentration;
			} else {
				this.roomData = this.room?.overall;
			}
		},
		prevDevice() {
			if (this.currentDeviceIndex > 0) {
				this.currentDeviceIndex--;
				this.currentDevice = this.devices[this.currentDeviceIndex];
			}
		},
		nextDevice() {
			if (this.currentDeviceIndex < this.devices?.length - 1) {
				this.currentDeviceIndex++;
				this.currentDevice = this.devices[this.currentDeviceIndex];
			}
		},
		prevRoom() {
			this.toogleChart();
			this.$emit("prev-room");
		},
		nextRoom() {
			this.toogleChart();
			this.$emit("next-room");
		},
		toogleChart() {
			this.renderChart = false;
			this.$nextTick(() => {
				this.renderChart = true;
			});
		},
		addTempo() {
			// console.log(this.iaqDetail);
			this.iaqDetail.data.temperature++;
			this.tempo = this.iaqDetail.data.temperature;
		},
		minusTempo() {
			this.iaqDetail.data.temperature--;
			this.tempo = this.iaqDetail.data.temperature;
		},
	},
};
</script>
<style></style>
